import template from '../RaceNew/race-games-modal.html'
let stats = 0
export const RacePrizeMap = (() => {
  let prizes = null
  var init = function (prizeData) {
    if (prizes !== null) {
      return
    }
    prizes = {}

    for (var i = 0; i < prizeData.length; i++) {
      var p = prizeData[i]
      prizes[p.id] = p
    }
  }
  var get = function (id) {
    return prizes[id]
  }
  return {
    init: init,
    get: get,
  }
});

export function Race(logged = true) {
  var instance = {}

  instance.prizes = RacePrizeMap()
  instance.activeRacePoints = {}
  instance.currentPoints = 0
  instance.maxPoints = 0
  instance.raceID = null
  instance.getRaceImage = function (templateId) {
    return (
      RizkConfig.staticAssetsUrl + 'race-images/race-bg-' + templateId + '.jpg'
    )
  }

  const  getPrizeTranslation = (element,raceElement,prizes) => {
    var transId = 'race.'+raceElement.template_id+'.prizepool';
    Rizk.Translations.get(transId, (translation) => {
      if(translation === transId)
      {translation = prizes.get(raceElement.prize_collection_id).prizes[0].prize;}
      element.innerText = translation;
    });
  }

  var getRandomImg = function () {
    var r = parseInt(2 + Math.random() * 6)
    return (
      RizkConfig.staticAssetsUrl +
      'race-images/default-backgrounds/rizk-race-card-bg' +
      r +
      '.jpg'
    )
  }


  instance.formatBet = function (minBet) {
    var currency = Rizk.localeCurrency[RizkConfig.locale]
    if (RizkConfig.logged === true) {
      currency = RizkConfig.currency
    }

    var minBetVal = minBet / 100
    if (currency === 'NOK' || currency === 'SEK') {
      minBetVal *= 10
    }
    if (currency === 'INR') {
      minBetVal *= 80
    }
    if (currency === 'HRK') {
      minBetVal *= 5
    }
    return intlFormatCurrency(minBetVal)
  }

  var showRaceGames = function () {
    Rizk.Search.showRaceGames()
    Rizk.SideMenu.gamesClick()
  }


  var newGameList = function (games, device) {
    console.log('populate games', games)
    var elem = document.createElement('ul')
    for (var i = 0; i < games.length; i++) {
      var game = games[i]
      var liElem = document.createElement('li')
      liElem.setAttribute('data-id', game.id)
      var imgElem = document.createElement('img')
      imgElem.width = '40'
      imgElem.height = '40'
      imgElem.src =
        RizkConfig.staticAssetsUrl +
        'game-assets/desktop-original/' +
        game.id +
        '.jpg'
      //imgElem.setAttribute("data-id", game);
      imgElem.setAttribute('data-link', game.url)
      imgElem.setAttribute('title', game.name)
      // eslint-disable-next-line
      imgElem.addEventListener('click', function (event) {
        var url = event.target.getAttribute('data-link')
        console.log('load game', url)
        Rizk.GameView.beforeLoadGame(url)
      })

      liElem.appendChild(imgElem)
      elem.appendChild(liElem)
    }
    return elem
  }


  var setBg = function (bgUrl, defaultUrl, elem) {
    var image = new Image()
    image.onload = function () {
      elem.style.backgroundImage = "url('" + bgUrl + "')"
    }
    image.onerror = function () {
      elem.style.backgroundImage = "url('" + defaultUrl + "')"
    }
    image.src = bgUrl
  }
  const formatDate = (dateString)=>{
    return dateString.replaceAll('-', '/')
  }

  const convertMinutesToVisualTime = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return `${rhours ? `${rhours}h` : ''}  ${rminutes}m`;
    }

  const parseRacesData = function (racedata, isGameView = false) {
    console.log('about to render races..', racedata)
    const raceContainer = !isGameView ? document.querySelector('.race-col-container') : document.querySelector('.gameview-race-container')
    const lobbyWidgetContainer = document.querySelector('#category-races')
    const upcomingRacesParent = document.querySelector('.race-upcoming')
    const upcomingText = document.querySelector('.race-col-race-upcoming')
    refreshContainer(raceContainer)
    refreshContainer(upcomingRacesParent)
    refreshContainer(lobbyWidgetContainer)
    clearCountDown()
    instance.prioRaces = [];
    instance.races = []
    instance.upcomingRaceCount = 0;
    instance.upcomingRacesContainerConfig = {};
    instance.activeRacePoints = {}
    if (!racedata.length) {
       const raceIndividualCheck = document.getElementsByClassName('race-individual').length > 0;
       if (raceIndividualCheck) {
         const raceIndividual = document.getElementsByClassName('race-individual');
         for (let i = 0; i < raceIndividual.length; i++) {
           raceIndividual[i].style.display = 'none';
         }
       }
      if (upcomingText) {
        Rizk.Translations.get('race_noraces', (translation) => {
          upcomingText.innerText = translation
        })
        upcomingText.classList.add('race-no-races')
      }
      if (RizkConfig.lobby && !(Rizk.isFeatureOn('react-lobby', RizkConfig) && Rizk.isFeatureActive('react-lobby', RizkConfig))) {
        document.querySelector('#categoryRowRacesTemplate').style.display = 'none'
      }
      return
    }
    for (i = 0; i < racedata.length; i++) {
      var race = racedata[i]
      race.from_time = formatDate(`${race.from_time} UTC`)
      race.to_time = formatDate(`${race.to_time} UTC`)
      // title = race.name

      if (race.current) {
        instance.prioRaces.push(race)
        console.log('CURRENT!', race)
        continue
      }else if (i === 0) {
        instance.prioRaces.push(race)
        continue
      }

      if (race.priority > prio) {
        if (instance.prioRaces.length < 3) {
          instance.prioRaces.push(race)
          continue
        }
      }
      const raceStartTime = race.from_time
      const isValidForUpcoming = isTodayTomorrow(new Date(raceStartTime))
      if (isValidForUpcoming) {
        instance.races.push(race)
      }
    }
    renderUpcomingRaces(upcomingText)
    return renderPriorityRaces(isGameView, raceContainer)
  }

  instance.chooseGameModal = (raceChoice, isGameView = false) => {
    const raceComponent = document.getElementById(`race-${raceChoice.raceId}`)
    const optinBtn = raceComponent.querySelector('.race-optin')
    optinBtn.classList.add('race-optin--choose');
    const parent = optinBtn.parentElement;
    const chooseGameButton = document.createElement('div');
    chooseGameButton.classList.add('rizk-btn', 'btn-clear', 'race-optin');
    const raceGames = raceChoice.game_whitelist;
    var pickGametranslation = '';
    if(raceGames.length > 1) {
      pickGametranslation = isGameView ? 'races_pick_another_game' : 'races_pick_game';
    } else {
      pickGametranslation = 'races_pick_game';
      if(isGameView) {
        chooseGameButton.style.display = 'none';
      }
    }
    Rizk.Translations.get(pickGametranslation, (translation) => {
      chooseGameButton.innerText += translation;
    });
    parent.appendChild(chooseGameButton);
    const buttonHasClass = optinBtn.classList.contains('race-optin--choose');
    if(buttonHasClass) {
        optinBtn.style.display = 'none';

        chooseGameButton.addEventListener('click', function () {
          instance.createRaceGameModal(raceChoice)
        });
    }
  }


  const renderHistoryRaces = async function (racedata) {
    const raceContainer = document.querySelector('.race-history-container')
    console.log('about to render history races..', racedata)
    for (i = 0; i < racedata.length; i++) {
      var race = racedata[i]
      race.from_time = formatDate(`${race.from_time} UTC`)
      race.to_time = formatDate(`${race.to_time} UTC`)
      // title = race.name
      createHistoryRace(race, raceContainer)
    }
    if ((racedata.length % 3) !== 0) {
      raceContainer.style.justifyContent = 'space-around'
    }
  }


  const renderPriorityRaces = function (isGameView, raceContainer) {
    if (instance.prioRaces.length) {
      raceContainer ? raceContainer.style.justifyContent = 'space-between' : undefined
      console.log('Creating proirity race', isGameView)
      if (isGameView) {
        return renderGameViewWidget(raceContainer, isGameView)
      }
      const iterations = 3
      for (var ii = 0; ii < iterations; ii++) {
        if (instance.prioRaces[ii]) {
          console.log('Checking prio races')
            createPriorityRace(instance.prioRaces[ii], raceContainer, isGameView)
        } else {
          raceContainer ? raceContainer.style.justifyContent = 'space-around' : undefined
          break
        }
      }
    }
  }
  const renderGameViewWidget = (raceContainer, gameId)=>{
    let gameViewWidgetReady = false
    let index = 0
    while (instance.prioRaces[index] && !gameViewWidgetReady) {
      const race = instance.prioRaces[index]
      if (race.current) {
        if (typeof race.game_whitelist !== 'undefined') {
          var wl = race.game_whitelist
          console.log('whitelist while looking for gameview', wl)
          for (i = 0; i < wl.length; i++) {
            if (wl[i].id === gameId) {
              console.log('found race whitelist while looking for gameview', race, wl[i].id)
              createPriorityRace(race, raceContainer, true)
              gameViewWidgetReady = true
              return [race, true]
            }
          }
        }
      }
      index++
    }
    if(!gameViewWidgetReady){
      createPriorityRace(instance.prioRaces[0], raceContainer, true)
    }
    return [instance.prioRaces[0], false]
  }

  const renderUpcomingRaces = function (upcomingText) {
    if (instance.races.length) {
      const raceContainer = document.querySelector('.race-col-race-upcoming-container')
      for (var ii = 0; ii < instance.races.length; ii++) {
        if (instance.races[ii]) {
          createUpcomingRace(instance.races[ii], raceContainer)
        }

      }
      if(upcomingText){
        upcomingText.classList.remove('race-no-races')
      }
    }else{
      if (upcomingText) {
        upcomingText.style.display = instance.upcomingRaceCount ? 'block' : 'none'
      }
    }
  }


  var inited = false
  var prio = 99
  instance.prioRaces = []
  instance.countdownIntervals = []

  instance.init = async function (isGameView = false) {
    console.log('init called!!!')
    return new Promise((resolve, reject) => {
      ajax(
        '/race/get/future',
        async (response) => {
          const json = JSON.parse(response.responseText)
          console.log(json, instance.prizes)
          instance.prizes.init(json.prizes)
          inited = true
          const parsedData = parseRacesData(json.races, isGameView)
          console.log('Data parsed!', parsedData)
          resolve(isGameView ? parsedData : json.races)
          return
        },
      )
    })
  }

  instance.history = async function () {
        ajax(
          '/race/get/history',
          async (response) => {
            const json = JSON.parse(response.responseText)
            console.log(json, json.prizes)
            instance.prizes.init(json.prizes)
            renderHistoryRaces(json.races)
          },
        )

    }


  instance.showOptinBanner = function (race) {
    var optinBannerElement = null;
    const raceContainer = document.getElementById(`race-${race.raceId}`);
    //optin banner element
    if(RizkConfig.device === 'mobile') {
      raceContainer.classList.add('race-opted-in-pl');
      raceContainer.querySelector('.race-col-img').classList.add('padded-race-img');
      optinBannerElement = raceContainer.querySelector('.race-opted-in-mob');
    } else {
      //for lobby
      raceContainer.classList.add('race-opted-in-ml');
      optinBannerElement = raceContainer.querySelector('.race-opted-in');
    }
    if(optinBannerElement) {
      optinBannerElement.setAttribute('style', 'display: block !important')
    }
  }

  instance.startRace = function (race) {
    instance.showOptinBanner(race);
    instance.chooseGameModal(race)
    console.log('start race', race)
    // update stats with user points
    stats = instance.updateUserStats(race)
    if (typeof race.leaderboard !== 'undefined') {
      instance.test = race.leaderboard;
      for (i = 0; i < race.leaderboard.length; i++) {
        if (race.leaderboard[i].uid === parseInt(RizkConfig.userId)) {
          stats.updatePoints(race.leaderboard[i].points)
        }
      }
    }
  }

  instance.updateOptinCount = (count, raceId) => {
    const raceWidget = document.getElementById(`race-${raceId}`)
    console.log('Race widget', raceWidget)
    if (!raceWidget) {
      return
    }
    const raceUsers = raceWidget.querySelector('.count')
    console.log('Count element', raceUsers)
    if (raceUsers) {
      raceUsers.innerHTML = count
    }
  }

  instance.raceNotAllowed = function(json) {
    console.log('race-game-not-allowed', json)
    if (
      RizkConfig.country === 'ES' ||
      Rizk.isFeatureActive('account-inbox', RizkConfig) === false
    ) {
      return
    }
    if (
      json.oneGame &&
      json.oneGame.id &&
      json.oneGame.name &&
      json.oneGame.slug
    ) {
      Rizk.NotificationController.show({
        timeout: 10000,
        message: {
          en:
            Rizk.Translations.get('race_game_not_allowed_link') +
            '<a href="/' +
            RizkConfig.locale +
            '/casino/' +
            json.oneGame.slug +
            '/' +
            json.oneGame.id +
            '">' +
            json.oneGame.name +
            '</a>!',
        },
      })
    } else {
      var optedIn = false;
      if(instance.races && instance.races.length > 0) {
        for (var i = 0; i < instance.races.length; i++) {
          if (instance.races[i] && instance.races[i].optin === true) {
            optedIn = true;
          }
        }
      }
      if(optedIn === true) {
        Rizk.NotificationController.show({
          timeout: 10000,
          message: { en: Rizk.Translations.get('race_game_not_allowed') },
        })
      }

    }
  }

  const createPriorityRace = function (race, raceContainer, isGameView = false) {
    if (!raceContainer) {
      return
    }
    const racecolContainer = document.getElementsByClassName('race-col-container')[0];
    if (typeof racecolContainer !== 'undefined') {
      const columns = racecolContainer.getElementsByClassName('race-individual');
      for (let i = 0; i < 3; i++) {
        columns[i].style.display = 'none';
        columns[i].querySelector('.race-individual__overlay').style.display = 'none';
      }
    }
    const raceIndividual = raceContainer.querySelector('.race-individual').cloneNode(true)
    const raceColumn = raceIndividual.querySelector('.race-col')
    const raceLeaderboardContainer = raceColumn.querySelector('.race-col-race-leaderboard-container')
    const raceImg = raceColumn.querySelector('.race-col-img')
    const startEnd = raceIndividual.querySelectorAll('.start-end')
    const startTime = raceIndividual.querySelectorAll('.start-time')
    const optinBtn = raceColumn.querySelector('.race-optin')
    const raceUsers = raceColumn.querySelector('.race-col-race-optin-details-optedin')
    const raceGameImageContainer = raceColumn.querySelector('.race-col-race-img-container')
    const raceLeaderBoardPlaceHolder = raceLeaderboardContainer.querySelector('#race-leaderboard-placeholder')
    const racePrizesPlaceHolder = raceLeaderboardContainer.querySelector('#race-prizes-placeholder')
    raceIndividual.classList.add('raceClone')
    const dateNow = new Date();
    let hour = dateNow.getHours();
    //raceIndividual.classList.add('show')
    //const raceClone = raceIndividual.getElementsByClassName('raceClone');
    console.log(raceIndividual.length);
    if(!raceIndividual) {
      console.log('no races showing');
    } else {
      console.log('races availible');
    }
    //console.log('length', raceClone.length);
    // for (let i = 0; i < raceClone.length; i++) {
    //   console.log('test', raceClone[i]);
    // }
    raceIndividual.id = `race-${race.raceId}`
    var date;
    if (!RizkConfig.lobby) {
      createLeaderboard(race, raceLeaderBoardPlaceHolder)
      createPrizes(race, racePrizesPlaceHolder)
      raceLeaderboardContainer.style.display = 'flex'
    }
    if (race.current) {
      date = new Date(race.to_time)
      Rizk.Translations.get('races_ends_in', (translation) => {
        startEnd.forEach((element) => {
          element.innerText = translation
        })
      })
      if (RizkConfig.logged) {
        Rizk.Translations.get('races_join_ongoing', (translation) => {
          optinBtn.innerText = translation
        })
      }
      //optinBtn.removeEventListener('click', optinPickGameEvent, true)
      if (race.optin) {
        if (!isGameView) {
          const checkForRaceInDOM = setInterval(function () {
            if (document.getElementById(raceIndividual.id)) {
              instance.startRace(race)
              clearInterval(checkForRaceInDOM);
            }
          }, 100);
        }
      }
      raceImg.querySelector('.race-col-timer').style.display = 'none'
      raceImg.querySelector('.race-start-time').style.display = 'none'
      raceImg.style.backgroundImage = `url(${instance.getRaceImage(race.template_id) + '?' + hour})`
      raceLeaderboardContainer.querySelector('#race-leaderboard-nav').click()
      raceUsers.querySelector('.count').innerHTML = race.optincount
      raceUsers.querySelector('.circle').style.background = 'green'
      raceUsers.querySelector('.circle').style.animation = 'glow 1s infinite alternate'
    } else {
      date = new Date(race.from_time)
      if (RizkConfig.logged) {
        optinBtn.style.background = 'var(--races-disabled-bg)'
        optinBtn.style.color = 'var(--races-disabled-color)'
        optinBtn.disabled = true
      }
      raceImg.style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${instance.getRaceImage(race.template_id) + '?' + hour})`
      raceLeaderboardContainer.querySelector('#race-prizes-nav').click()
    }
    optinBtn.addEventListener('click', optinPickGameEvent, true)

    createRaceTiles(race, raceGameImageContainer);
    raceGameImageContainer.addEventListener('click', function () {
      instance.createRaceGameModal(race)
    })
    raceIndividual.style.display = 'block'
    raceColumn.querySelector('.race-col-name').innerText = race.name
    getPrizeTranslation(raceColumn.querySelector('#race-prize'),race, instance.prizes);
    raceColumn.querySelector('#race-minbet').innerText = instance.formatBet(race.minBet)
    raceColumn.querySelector('#race-spins').innerText = race.maxRounds
    raceColumn.querySelector('#race-duration').innerText = convertMinutesToVisualTime(race.length)
    const cd = new RizkTimer()
    var ts = date.getTime() / 1000
    const startTimeElements = []
    startTime.forEach((element) => {
      startTimeElements.push(element)
    })
    countDownPush(cd.start(ts, startTimeElements))
    cd.addStopCallback(function () {
      console.log('stopCB init() ', race.raceId)
      raceOverAction()
    })
    raceContainer.appendChild(raceIndividual)
    function optinPickGameEvent() {
      pickGame()
    }

    const pickGame = () => {
      if (RizkConfig.logged) {
        if (race.current) {
          console.log(race.current);
          var optinCb = function (json) {
            var json = JSON.parse(json.responseText)
            if (!json.success) {
              console.log('trying to display errormsg', json)
              if (json.message) {
                console.log('displaying error msg')
                showNotificationModal({
                  id: 'race-restricted',
                  heading: json.message.title,
                  text: json.message.content,
                  classname: 'red',
                })
              }
              return
            }
            race.optin = true
            raceUsers.querySelector('.count').innerHTML = race.optincount + 1
            if (!isGameView) {
              instance.startRace(race)
            } else {
              Rizk.GameView.handleRace(race)
            }
          }
          ajax('/race/ongoing/' + race.raceId, optinCb)
        }
      } else {
        showLoginModal()
      }
    }
    return raceIndividual
  }

  const createHistoryRace = function (race, raceContainer) {
    if (RizkConfig.lobby || !raceContainer) {
      return
    }
    const raceIndividual = raceContainer.querySelector('.race-individual').cloneNode(true)
    const raceColumn = raceIndividual.querySelector('.race-col')
    const raceLeaderboardContainer = raceColumn.querySelector('.race-col-race-leaderboard-container')
    const raceImg = raceColumn.querySelector('.race-col-img')
    const startEnd = raceIndividual.querySelectorAll('.start-end')
    const startTime = raceIndividual.querySelectorAll('.start-time')
    const optinBtn = raceColumn.querySelector('.race-optin')
    const raceUsers = raceColumn.querySelector('.race-col-race-optin-details-optedin')
    const raceGameImageContainer = raceColumn.querySelector('.race-col-race-img-container')
    const raceLeaderBoardPlaceHolder = raceLeaderboardContainer.querySelector('#race-leaderboard-placeholder')
    const racePrizesPlaceHolder = raceLeaderboardContainer.querySelector('#race-prizes-placeholder')
    raceIndividual.classList.add('raceClone')
    raceIndividual.id = `race-${race.raceId}`
    const dateNow = new Date();
    let hour = dateNow.getHours();
    console.log(hour);
    raceImg.querySelector('.race-col-timer').style.display = 'none'
    raceImg.querySelector('.race-start-time').style.display = 'none'
    raceImg.style.backgroundImage = `url(${instance.getRaceImage(race.template_id) + '?' + hour})`
    raceImg.style.filter = 'grayscale(100%)'
    optinBtn.style.display = 'none'
    createLeaderboard(race, raceLeaderBoardPlaceHolder)
    createPrizes(race, racePrizesPlaceHolder)

    var mElemLabel = document.createElement('span');
    mElemLabel.id = 'testingposition';
    Rizk.Translations.get('race.myposition', (translation) => {
      mElemLabel.innerText = translation
    });
    var mElem = raceColumn.querySelector('#race-duration-streak');
    mElem.classList.add('lb-meters');
    mElem.querySelectorAll('span').forEach(element => {
      element.style.display = 'none';
    })
    mElem.appendChild(mElemLabel);

    var userPositionEl = document.createElement('span');
    userPositionEl.id = 'testinguserposition';
    const userRacePosition = race.leaderboard || []
    var userPos = null;
    if (userRacePosition !== null && userRacePosition.length) {
      userRacePosition.forEach((user, pos) => {
        if (parseInt(user.uid) === parseInt(RizkConfig.userId)) {
          userPos = pos;
        }
      });
      if(userPos !== null) {
        userPositionEl.innerText = String((userPos + 1));
        userPositionEl.classList.add('position-assigned');
      } else {
        Rizk.Translations.get('race.position-not-found', (translation) => {
          userPositionEl.innerText = translation
        });
      }
    } else {
      Rizk.Translations.get('race.position-not-found', (translation) => {
        userPositionEl.innerText = translation
      });
    }
    mElem.appendChild(userPositionEl);
    var racePrizeElements = racePrizesPlaceHolder.querySelectorAll('.race-col-race-leaderboard-item.raceClone');
    racePrizeElements.forEach((prizeElement, pos) => {
      if(pos === userPos) {
        prizeElement.classList.add('prize_for_user');
      }
    });

    raceLeaderboardContainer.querySelector('#race-leaderboard-nav').click()
    // raceColumn.querySelector('#race-duration').innerText = convertMinutesToVisualTime(race.length)
    raceUsers.querySelector('.count').innerHTML = race.optincount
    Rizk.Translations.get('races_ended', (translation) => {
      startEnd.forEach((element) => {
        element.innerText = translation
      })
    })
    createRaceTiles(race, raceGameImageContainer);
    raceIndividual.style.display = 'block'
    raceColumn.querySelector('.race-col-name').innerText = race.name
    getPrizeTranslation(raceColumn.querySelector('#race-prize'),race, instance.prizes);
    raceColumn.querySelector('#race-minbet').innerText = instance.formatBet(race.minBet)
    raceColumn.querySelector('#race-spins').innerText = race.maxRounds
    var date = new Date(
      race.to_time
    )
    startTime.forEach((element) => {
      element.innerText = date.toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })
    })

    raceLeaderboardContainer.style.display = 'flex'
    raceContainer.appendChild(raceIndividual)
  }

  instance.optinButtonClick = function (race) {
    return new Promise(async (resolve, reject) => {
      if (RizkConfig.logged) {
        if (race.current) {
          console.log('opt in to', race)
          var optinCb = function (response) {
            var json = JSON.parse(response.responseText)
            console.log('opt in cb', json)
            if (!json.success) {
              console.log('trying to display errormsg')
              if (json.message) {
                console.log('displaying error msg')
                showNotificationModal({
                  id: 'race-restricted',
                  heading: json.message.title,
                  text: json.message.content,
                  classname: 'red',
                })
              }
              resolve(false);
            }
            resolve(true);
          }
          await ajax('/race/ongoing/' + race.raceId, optinCb)
        }
      } else {
        showLoginModal();
        resolve(false);
      }
    });
  }

  instance.createRaceWidget = function (race, raceWidget, parent) {
    if (RizkConfig.device === 'mobile') {
      console.log('parent', parent);
      createPriorityRace(race, parent)
      return parent
    }
    const widgetContainer = raceWidget.querySelector('.race-widget-container')
    const raceLeaderboardContainer = raceWidget.querySelector('.race-col-race-leaderboard-container')
    const widgetDataContainer = widgetContainer.querySelector('.race-widget-data-container')
    const widgetImgOptinContainer = widgetContainer.querySelector('.race-widget-img-optin-container')
    const raceImg = widgetImgOptinContainer.querySelector('.race-widget-img')
    const widgetData = widgetDataContainer.querySelector('.race-widget-data')
    const optinBtn = raceWidget.querySelector('.race-optin')
    const raceGameImageContainer = widgetDataContainer.querySelector('.race-widget-game-thumbnail-container')
    const startTime = widgetContainer.querySelectorAll('.start-time')
    const startEnd = widgetContainer.querySelectorAll('.start-end')
    const raceLeaderBoardPlaceHolder = raceLeaderboardContainer.querySelector('#race-leaderboard-placeholder')
    const racePrizesPlaceHolder = raceLeaderboardContainer.querySelector('#race-prizes-placeholder')
    const raceUsers = widgetContainer.querySelector('.race-widget-race-optin-details-optedin')
    raceWidget.classList.add('raceClone')
    raceWidget.id = `race-${race.raceId}`
    let index = 0;
    const dateNow = new Date();
    let hour = dateNow.getHours();
    while (race.game_whitelist[index] && index < 4) {
      const raceGame = race.game_whitelist[index];
      const img = document.createElement('div')
      img.classList.add('race-col-race-img')
      let src = getGameTileSrc(raceGame)
      if (index === 3 && race.game_whitelist.length > 4) {
        img.style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${src})`
        img.innerText = `+${race.game_whitelist.length - (index)}`
      } else {
        img.style.backgroundImage = `url(${src})`
      }
      raceGameImageContainer.appendChild(img)
      index++
    }
    raceGameImageContainer.addEventListener('click', function () {
      instance.createRaceGameModal(race)
    })
    getPrizeTranslation(raceWidget.querySelector('#race-prize'),race, instance.prizes);
    widgetData.querySelector('#race-minbet').innerText = instance.formatBet(race.minBet)
    widgetData.querySelector('#race-spins').innerText = race.maxRounds
    widgetData.querySelector('#race-duration').innerText = convertMinutesToVisualTime(race.length)
    var date;
    if (race.current) {
      date = new Date(race.to_time)
      Rizk.Translations.get('races_ends_in', (translation) => {
        startEnd.forEach((element) => {
          element.innerText = translation
        })
      })
      if (RizkConfig.logged) {
        Rizk.Translations.get('races_join_ongoing', (translation) => {
          optinBtn.innerText = translation
        })
      }
      raceImg.querySelector('.race-col-timer').style.display = 'none'
      raceImg.querySelector('.race-start-time').style.display = 'none'
      raceImg.style.backgroundImage = `url(${instance.getRaceImage(race.template_id) + '?' + hour})`
      raceUsers.querySelector('.count').innerHTML = race.optincount
      raceUsers.querySelector('.circle').style.background = 'green'
      raceUsers.querySelector('.circle').style.animation = 'glow 1s infinite alternate'
    } else {
      date = new Date(race.from_time)
      if (RizkConfig.logged) {
        optinBtn.style.background = 'var(--races-disabled-bg)'
        optinBtn.style.color = 'var(--races-disabled-color)'
        optinBtn.disabled = true
      }
      raceImg.style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${instance.getRaceImage(race.template_id) + '?' + hour })`

    }
    optinBtn.addEventListener('click', function () {
      if (RizkConfig.logged) {
        if (race.current) {
          console.log('opt in to', race)
          var optinCb = function (response) {
            var json = JSON.parse(response.responseText)
            console.log('opt in cb', json)
            if (!json.success) {
              console.log('trying to display errormsg')
              if (json.message) {
                console.log('displaying error msg')
                showNotificationModal({
                  id: 'race-restricted',
                  heading: json.message.title,
                  text: json.message.content,
                  classname: 'red',
                })
              }
              return
            }
            race.optin = true
            instance.showOptinBanner(race);
            instance.chooseGameModal(race);
            raceUsers.querySelector('.count').innerHTML = race.optincount + 1
          }
          ajax('/race/ongoing/' + race.raceId, optinCb)
        }
      } else {
        showLoginModal()
      }
    })
    widgetContainer.querySelector('.name').innerText = race.name
    createLeaderboard(race, raceLeaderBoardPlaceHolder)
    createPrizes(race, racePrizesPlaceHolder)
    // showCollapsible(raceLeaderboardContainer)
    if ('category-races' !== parent.id) {
      raceLeaderboardContainer.style.display = 'flex'
    }
    raceLeaderboardContainer.querySelector('#race-prizes-nav').click()
    const cd = new RizkTimer()
    const startTimeElements = []
    startTime.forEach((element) => {
      startTimeElements.push(element)
    })
    var ts = date.getTime() / 1000
    countDownPush(cd.start(ts, startTimeElements))
    cd.addStopCallback(function () {
      console.log('stopCB init() ', race.raceId)
      raceOverAction()
    })
    raceWidget.style.display = 'block'
    parent.appendChild(raceWidget)
    if (race.optin) {
      instance.showOptinBanner(race);
      instance.chooseGameModal(race)
    }
    return raceWidget;
  }

  const raceOverAction = () => {
    setTimeout(
      RizkConfig.lobby ? Rizk.LobbyV2.initRaceWidget.bind(Rizk.LobbyV2) :
        RizkConfig.inGame ? Rizk.GameView.initRaceWidget(Rizk.GameView) :
          instance.init.bind(instance)
      , Math.floor(Math.random() * 3000) + 1)
  }
  const createPrizes = function (race, parent) {
    const mobileUserLimit = 5
    let leaderboardCount = 0;
    const prizesForRace = instance.prizes.get(race.prize_collection_id).prizes
    prizesForRace.forEach((prize, pos) => {
      const leaderBoardItemElement = parent.parentNode.querySelector('.race-col-race-leaderboard-item:not(.raceClone)').cloneNode(true)
      leaderBoardItemElement.querySelector('.name').innerText = ''
      leaderBoardItemElement.querySelector('.pos').innerText = pos + 1
      leaderBoardItemElement.querySelector('.prize').innerText = prize.prize
      // console.log(prize)
      // leaderBoardItemElement.querySelector('.prize').setAttribute('data-type', prize.type);
      const prizeImg = leaderBoardItemElement.querySelector('img')
      prizeImg.src = `/assets/img/race/Rizk-race-prize-${pos > 2 ? '4+' : pos + 1}.png`
      leaderBoardItemElement.style.display = 'flex'
      leaderBoardItemElement.classList.add('raceClone');
      parent.appendChild(leaderBoardItemElement)
      leaderboardCount++
    })
    if (leaderboardCount > mobileUserLimit) {
      parent.classList.add('expandable')
      // showCollapsible(parent.parentNode, parent)
    }
    parent.id = `${parent.id}-${race.raceId}`
    instance.raceID = parent.id
  }

const createRaceTiles = (race, raceGameImageContainer) =>{
  const gamesLength = race.game_whitelist.length
  const dateNow = new Date();
  let hour = dateNow.getHours();
  if (gamesLength === 1) {
    const raceGame = race.game_whitelist[0];
    const img = document.createElement('div')
    img.classList.add('race-col-race-img')
    let src = getGameTileSrc(raceGame)
    img.style.backgroundImage = `url(${src})`
    img.style.height = '125px'
    img.style.width = '125px'
    img.style.maxWidth = 'unset'
    raceGameImageContainer.appendChild(img)
  } else {
    let index = 0;
    while (race.game_whitelist[index] && index < 4) {
      const raceGame = race.game_whitelist[index];
      const img = document.createElement('div')
      img.classList.add('race-col-race-img')
      let src = getGameTileSrc(raceGame)
      if (index === 3 && race.game_whitelist.length > 4) {
        img.innerText = `+${race.game_whitelist.length - (index)}`
        img.style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${src})`
      }else{
        img.style.backgroundImage = `url(${src})`
      }
      raceGameImageContainer.appendChild(img)
      index++
    }
  }
}

  const createLeaderboard = function (race, parent) {
    const mobileUserLimit = 5
    let userPosition;
    const userRacePosition = race.leaderboard || []
    let leaderboardCount = 0;
    if (userRacePosition.length) {
      userRacePosition.forEach((user, pos) => {
        const leaderBoardItemElement = parent.parentNode.querySelector('.race-col-race-leaderboard-item:not(.raceClone)').cloneNode(true)
        leaderBoardItemElement.querySelector('.name').innerText = user.name ? user.name.trim() : '-'
        leaderBoardItemElement.querySelector('.pos').innerText = pos + 1
        leaderBoardItemElement.querySelector('.prize').innerText = user.points
        const prizeImg = leaderBoardItemElement.querySelector('img')
        prizeImg.src = `/assets/img/race/Rizk-race-prize-${pos > 2 ? '4+' : pos + 1}.png`
        leaderBoardItemElement.style.display = 'flex'
        if (parseInt(user.uid) === parseInt(RizkConfig.userId)) {
          leaderBoardItemElement.classList.add('current-user')
          userPosition = pos
        }
        leaderBoardItemElement.classList.add('raceClone');
        parent.appendChild(leaderBoardItemElement)
        leaderboardCount++
      })
      if (race.current) {
        instance.activeRacePoints[`${race.raceId}`] = userRacePosition
      }
    } else {
      for (let index = 0; index < instance.prizes.get(race.prize_collection_id).prizes.length; index++) {
        const leaderBoardItemElement = parent.parentNode.querySelector('.race-col-race-leaderboard-item:not(.raceClone)').cloneNode(true)
        leaderBoardItemElement.querySelector('.name').innerText = '-'
        leaderBoardItemElement.querySelector('.pos').innerText = index + 1
        leaderBoardItemElement.querySelector('.prize').innerText = '-'
        const prizeImg = leaderBoardItemElement.querySelector('img')
        prizeImg.src = `/assets/img/race/Rizk-race-prize-${index > 2 ? '4+' : index + 1}.png`
        leaderBoardItemElement.style.display = 'flex'
        parent.appendChild(leaderBoardItemElement)
        leaderboardCount++
        leaderBoardItemElement.classList.add('raceClone');
      }
    }
    if (leaderboardCount > mobileUserLimit) {
      parent.classList.add('expandable')
      //showCollapsible(parent.parentNode, parent)
    }
    if ((userPosition !== undefined && userPosition < mobileUserLimit) && (leaderboardCount >= mobileUserLimit)) {
      parent.classList.add('user_in_limit')
    }
    parent.id = `${parent.id}-${race.raceId}`
  }
  /*
  update race points ws sample message
  ['data',
  { 'type': 'race-points',
  'points':
  { '169342':
    [
    { 'uid': '200992', 'raceId': 169342, 'points': '6860', 'name': 'Frank Henry', 'countryCode': 'NO', 'country': 'Norway', 'lastPoints': 1657708972188, 'lastGame': 'Sweet Bonanza', 'spins': 500 },
    { 'uid': '36097', 'raceId': 169342, 'points': '5890', 'name': 'Marko Taneli Johannes', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657710254757, 'lastGame': 'Sweet Bonanza', 'spins': 145 },
    { 'uid': '36740', 'raceId': 169342, 'points': '5390', 'name': 'Minna', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657711695140, 'lastGame': 'Sweet Bonanza', 'spins': 500 },
    { 'uid': '1209797', 'raceId': 169342, 'points': '3680', 'name': 'Kyle', 'countryCode': 'CA', 'country': 'Canada', 'lastPoints': 1657709429066, 'lastGame': 'Sweet Bonanza', 'spins': 241 },
    { 'uid': '540279', 'raceId': 169342, 'points': '6690', 'name': 'Emma', 'countryCode': 'NZ', 'country': 'New Zealand', 'lastPoints': 1657717212449, 'lastGame': 'Sweet Bonanza', 'spins': 168 },
    { 'uid': '47074', 'raceId': 169342, 'points': '3040', 'name': 'Tanja', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657710780320, 'lastGame': 'Sweet Bonanza', 'spins': 212 },
    { 'uid': '180390', 'raceId': 169342, 'points': '5910', 'name': 'Åsne', 'countryCode': 'NO', 'country': 'Norway', 'lastPoints': 1657718819401, 'lastGame': 'Sweet Bonanza', 'spins': 142 },
    { 'uid': '1207696', 'raceId': 169342, 'points': '5580', 'name': 'Pavlos', 'countryCode': 'NZ', 'country': 'New Zealand', 'lastPoints': 1657717407402, 'lastGame': 'Sweet Bonanza', 'spins': 447 },
    { 'uid': '974225', 'raceId': 169342, 'points': '730', 'name': 'Päivi', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657712209570, 'lastGame': 'Sweet Bonanza', 'spins': 83 },
    { 'uid': '192977', 'raceId': 169342, 'points': '7040', 'name': 'Juha', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657714078207, 'lastGame': 'Sweet Bonanza', 'spins': 500 },
    { 'uid': '581429', 'raceId': 169342, 'points': '2840', 'name': 'Jaana', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657713716445, 'lastGame': 'Sweet Bonanza', 'spins': 225 },
    { 'uid': '129902', 'raceId': 169342, 'points': '6330', 'name': 'Jani', 'countryCode': 'FI', 'country': 'Finland', 'lastPoints': 1657715914004, 'lastGame': 'Sweet Bonanza', 'spins': 500 }
    ]
  }
}
]
*/
  instance.updateRacePoints = function (raceId, pointsArray = [], shouldUpdateCurrentPoints = false, shouldUpdateMaxPoints = false) {
    const mobileUserLimit = 5
    const raceLeaderBoardPlaceHolder = document.getElementById(`race-leaderboard-placeholder-${raceId}`)
    if (!raceLeaderBoardPlaceHolder) {
      return;
    }
    if (shouldUpdateCurrentPoints) {
      console.log('Update current points only', instance.currentPoints)
      const leaderBoardItemElement = raceLeaderBoardPlaceHolder.querySelector('.current-user')
      if (leaderBoardItemElement) {
        const currentRacePointsScore = leaderBoardItemElement.querySelector('.points')
        currentRacePointsScore.textContent = instance.currentPoints
      }
      return
    }
    if (shouldUpdateMaxPoints) {
      console.log('Update max points only', instance.maxPoints)
      const leaderBoardItemElement = raceLeaderBoardPlaceHolder.querySelector('.current-user')
      if (leaderBoardItemElement) {
        const maxRacePointsScore = leaderBoardItemElement.querySelector('.prize')
        maxRacePointsScore.textContent = instance.maxPoints
      }
      return
    }

    if (pointsArray.length && RizkConfig.logged) {
      pointsArray = sortPointsArray(pointsArray)
      const position = checkUserPosition(pointsArray, raceId);
      if (pointsArray.length !== (instance.activeRacePoints[`${raceId}`] ? instance.activeRacePoints[`${raceId}`].length : 0)) {
       repaintLeaderboard(raceLeaderBoardPlaceHolder, pointsArray)
      }else{
       reUseLeaderboard(raceLeaderBoardPlaceHolder, pointsArray)
      }
      instance.activeRacePoints[`${raceId}`] = pointsArray
      const classListLeaderboard = raceLeaderBoardPlaceHolder.classList
      console.log('Race positions', position, position < mobileUserLimit)
      if (instance.activeRacePoints[`${raceId}`].length > mobileUserLimit) {
        classListLeaderboard.add('expandable')
      }else{
        classListLeaderboard.remove('expandable')
      }
      if ((position !== null && position < mobileUserLimit) && (instance.activeRacePoints[`${raceId}`].length >= mobileUserLimit)) {
        classListLeaderboard.add('user_in_limit')
      }else{
        classListLeaderboard.remove('user_in_limit')
      }
    }
  }

  const sortPointsArray = (pointsArray) => {
    pointsArray.sort(function (a, b) {
      return a.points === b.points ? a.lastPoints - b.lastPoints : b.points - a.points;
    });
    return pointsArray
  }

  const checkUserPosition = (pointsArray, raceId)=> {
    const gvContainer = document.querySelector('#game-aside');
    const userPositionEl = document.querySelector('#testinguserposition');
    const userRacePosition = pointsArray;
    let userPos = null;
    if(userPositionEl && userRacePosition) {
      if (userRacePosition.length && userPositionEl) {
        userRacePosition.forEach((user, pos) => {
          if (parseInt(user.uid) === parseInt(RizkConfig.userId)) {
            userPos = pos;
          }
        });
        if(gvContainer === null) {
          if(userPos !== null) {
            userPositionEl.textContent = String((parseInt(userPos  + 1)));
          } else {
            Rizk.Translations.get('race.position-not-found', (translation) => {
              userPositionEl.innerText = translation
            });
          }
        }

      } else {
        if(gvContainer === null) {
          Rizk.Translations.get('race.position-not-found', (translation) => {
            userPositionEl.innerText = translation
          });
        }
      }

      const racePrizeLeaderboard = document.getElementById(`race-prizes-placeholder-${raceId}`);
      const racePrizeElements = racePrizeLeaderboard.querySelectorAll('.race-col-race-leaderboard-item.raceClone');
      racePrizeElements.forEach((prizeEle, pos) => {
        if(userPos !== null && pos === userPos) {
          prizeEle.classList.add('prize_for_user');
        }else{
          prizeEle.classList.remove('prize_for_user');
        }
      });
    }
    return userPos
  }
  const repaintLeaderboard = (raceLeaderBoardPlaceHolder, pointsArray)=>{
    refreshContainer(raceLeaderBoardPlaceHolder)
    pointsArray.forEach((user, pos) => {
      const leaderBoardItemElement = raceLeaderBoardPlaceHolder.parentNode.querySelector('.race-col-race-leaderboard-item:not(.raceClone)').cloneNode(true)
      leaderBoardItemElement.querySelector('.name').textContent = user.name ? user.name.trim() : '-'
      leaderBoardItemElement.querySelector('.pos').textContent = pos + 1
      const prizeImg = leaderBoardItemElement.querySelector('img')
      prizeImg.src = `/assets/img/race/Rizk-race-prize-${pos > 2 ? '4+' : pos + 1}.png`
      leaderBoardItemElement.style.display = 'flex'
      leaderBoardItemElement.classList.add('raceClone');
      const maxPoints = leaderBoardItemElement.querySelector('.prize')
      if (user.uid === RizkConfig.userId) {
        const currentRacePointsScore = leaderBoardItemElement.querySelector('.points')
        currentRacePointsScore.textContent = instance.currentPoints
        maxPoints.textContent = instance.maxPoints
        leaderBoardItemElement.classList.add('current-user')
      }else{
        maxPoints.textContent = user.points
      }
      raceLeaderBoardPlaceHolder.appendChild(leaderBoardItemElement)
    })
  }

  const reUseLeaderboard = (raceLeaderBoardPlaceHolder, pointsArray)=>{
    const leaderBoardItemElements = raceLeaderBoardPlaceHolder.querySelectorAll('.race-col-race-leaderboard-item.raceClone')
    leaderBoardItemElements.forEach((element, pos)=>{
      const user = pointsArray[pos]
      element.querySelector('.name').textContent = user.name ? user.name.trim() :'-'
      element.querySelector('.pos').textContent = pos + 1
      const maxPoints = element.querySelector('.prize')
      if (user.uid === RizkConfig.userId) {
        const currentRacePointsScore = element.querySelector('.points')
        currentRacePointsScore.textContent = instance.currentPoints
        maxPoints.textContent = instance.maxPoints
        element.classList.add('current-user')
      }else{
        element.classList.remove('current-user')
        maxPoints.textContent = user.points
      }
    })
  }


  const createUpcomingRace = function (race, upcomingRacesContainer) {
    const upcomingRaces = document.querySelector('.race-upcoming')
    if (!upcomingRacesContainer) {
      return
    }
    const raceStartTime = race.from_time
    const upcomingRaceItemContainer = upcomingRacesContainer.querySelector('.race-col-race-upcoming-item-container').cloneNode(true)
    const upcomingRaceItem = upcomingRaceItemContainer.querySelector('.race-col-race-upcoming-item')
    const raceWidgetClone = upcomingRacesContainer.querySelector('.race-widget').cloneNode(true)
    upcomingRaceItem.style.display = 'flex'
    upcomingRaceItemContainer.classList.add('raceClone');
    upcomingRaceItem.querySelector('.name').innerText = race.name
    upcomingRaceItem.querySelector('.prize').innerText = instance.prizes.get(race.prize_collection_id).prizes[0].prize
    upcomingRaceItem.querySelector('.day').innerText = isTodayTomorrow(new Date(raceStartTime))
    upcomingRaceItem.querySelector('.time').innerText = getStartDifference(raceStartTime)

    let showMoreBtn = document.getElementById('js-upcoming-show-more');
    let showLessBtn = document.getElementById('js-upcoming-show-less');
    const upComingParentContainer = document.getElementById('js-race-col-race-upcoming-container');
    const upComingItemELements = document.getElementsByClassName('race-col-race-upcoming-item-container--hide');
    let childen = upComingParentContainer.children;
    let slice = 11;

    setTimeout(
    () => {
      if (childen.length - 1 > 11) {
        showMoreBtn.style.display = 'flex';
      }
    }, 2000);

    showMoreBtn.addEventListener('click', () => {
      slice = slice + 11;
      let firstElements = Array.from(childen).slice(1, slice);
      firstElements.forEach(element => {
        element.classList.remove('race-col-race-upcoming-item-container--hide');
      });
      let itemsLength = upComingItemELements.length;

      if(itemsLength <= 1 ) {
        showMoreBtn.style.display = 'none';
        showLessBtn.style.display = 'flex';
      }
    });


    showLessBtn.addEventListener('click', () => {
      for (let child of childen) {
        child.classList.add('race-col-race-upcoming-item-container--hide');
      }
      slice = 11;
      showMoreBtn.style.display = 'flex';
      showLessBtn.style.display = 'none';
    });

    let widget;
    if (RizkConfig.device === 'mobile') {
      widget = createPriorityRace(race, upcomingRaceItemContainer)
    } else {
      widget = instance.createRaceWidget(race, raceWidgetClone, upcomingRaceItemContainer)
    }
    console.log('widgets offsets', widget.offsetHeight)
    widget.style.display = 'none'
    upcomingRaceItem.querySelector('.expand').addEventListener('click', function () {
      const containerHeight = upcomingRacesContainer.offsetHeight
      widget.style.display = 'block'
      console.log('Event target expand', widget.offsetHeight, containerHeight, upcomingRaceItem.offsetHeight)
      upcomingRacesContainer.style.maxHeight = `${widget.offsetHeight + containerHeight - upcomingRaceItem.offsetHeight}px`
      upcomingRaceItem.style.display = 'none'
    }, false)
    widget.querySelector('.race-collapse').addEventListener('click', function (event) {
      const containerHeight = upcomingRacesContainer.offsetHeight
      upcomingRaceItem.style.display = 'flex'
      console.log('Event target race-collapse', upcomingRacesContainer.offsetHeight, widget.offsetHeight, upcomingRaceItem.offsetHeight)
      upcomingRacesContainer.style.maxHeight = `${containerHeight - widget.offsetHeight + upcomingRaceItem.offsetHeight}px`
      widget.style.display = 'none'
    }, false)
    upcomingRacesContainer.appendChild(upcomingRaceItemContainer)
    upcomingRaces.appendChild(upcomingRacesContainer)
    instance.upcomingRaceCount++
  }

  const isTodayTomorrow = function (date) {
    const today = new Date()
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (tomorrow.toDateString() === date.toDateString()) {
      return Rizk.Translations.get('race_tomorrow')
    } else if (today.toDateString() === date.toDateString()) {
      return Rizk.Translations.get('race_today')
    }
    return false;
  }


  const getStartDifference = function (time) {
    var fromTime =
      new Date(
        time
      )
    return fromTime.getHours() + ':' + ('0' + fromTime.getMinutes()).slice(-2)
  }

  const refreshContainer = function (raceContainer) {
    if (!raceContainer) {
      return
    }
    const elements = raceContainer.getElementsByClassName('raceClone');
    while (elements[0]) {
      elements[0].remove();
    }
  }

  instance.showBoard = function (evt, boardType) {
    var i, x, tablinks;
    const leaderboardParentContainer = evt.target.parentElement.parentElement.parentElement
    x = leaderboardParentContainer.querySelectorAll('.board');
    tablinks = leaderboardParentContainer.querySelectorAll('hr');

    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      tablinks[i].style.display = 'none';
    }
    const placeHolder = leaderboardParentContainer.querySelector(`[id*=${boardType}-placeholder`)
    placeHolder.style.display = 'block';
    showCollapsible(leaderboardParentContainer, placeHolder)
    const navElement = evt.target.querySelector('hr') || evt.target.parentElement.querySelector('hr')
    navElement.style.display = 'block'
  }

  const showCollapsible = function (leaderboard, content) {
    const upcomingContainer = document.querySelector('.race-col-race-upcoming-container')
    if (RizkConfig.device !== 'mobile') {
      return
    }
    const showMoreElement = leaderboard.querySelectorAll('.show-more.raceClone')
    showMoreElement.forEach((element) => {
      element.remove()
    })
    if (!content.classList.contains('expandable')) {
      return
    }
    var coll = leaderboard.querySelector('.show-more:not(raceClone)').cloneNode(true);
    coll.style.display = 'flex'
    coll.style.position = 'relative'
    coll.classList.add('raceClone')
    const leaderBoardContainer = leaderboard.querySelector('.race-col-race-leaderboard-container') ||
      leaderboard.parentNode.querySelector('.race-col-race-leaderboard-container')
    leaderBoardContainer.appendChild(coll)
    console.log('Appended to', leaderBoardContainer, content)
    coll.addEventListener('click', expandCollapse);
    function expandCollapse() {
      var boards = leaderboard.querySelectorAll('.board');
      boards.forEach((board) => {
        if (board.style.display !== 'none') {
          const content = board
          console.log('leaderboadContainer', content)
          if (content.style.maxHeight) {
            if (upcomingContainer) {
              if (upcomingContainer.querySelector(`#${content.id}`)) {
                upcomingContainer.style.maxHeight = `${upcomingContainer.offsetHeight - (content.scrollHeight - 110)}px`
                }
            }
            content.style.maxHeight = null;
            coll.querySelector('img').style.transform = 'unset';
            Rizk.Translations.get('race_showmore', (translation) => {
              coll.querySelector('span').innerText = translation
            })
          } else {
            const offsetHeight = content.offsetHeight
            content.style.maxHeight = content.scrollHeight + 'px';
            coll.querySelector('img').style.transform = 'scaleY(-1)';
            Rizk.Translations.get('race_showless', (translation) => {
              coll.querySelector('span').innerText = translation
            })
            if (upcomingContainer) {
              if (upcomingContainer.querySelector(`#${content.id}`)) {
                upcomingContainer.style.maxHeight = `${upcomingContainer.offsetHeight + (content.scrollHeight - offsetHeight)}px`
              }
              }
          }
        }
      })
    }
  }

  // const showUpcomingCollapsible = function (upcomingContainer) {
  //   if (instance.upcomingRaceCount < 13) {
  //     return
  //   }
  //   const upcomingShowMore = upcomingContainer.parentNode.querySelector('.show-more').cloneNode(true);
  //   upcomingShowMore.classList.add('raceClone')
  //   const upcomingContainerSyles = upcomingContainer.style
  //   instance.upcomingRacesContainerConfig.section = Math.floor(instance.upcomingRaceCount / 12)
  //   upcomingShowMore.style.display = 'flex'
  //   instance.upcomingRacesContainerConfig.height = 516
  //   upcomingContainerSyles.maxHeight = `${instance.upcomingRacesContainerConfig.height}px`
  //   upcomingShowMore.addEventListener('click', function (event) {
  //     instance.upcomingRacesContainerConfig.section = instance.upcomingRacesContainerConfig.section - 0.5
  //     const widgets = upcomingContainer.querySelectorAll('.race-collapse')
  //     for (let widget of widgets) {
  //       if (widget.offsetWidth > 0 && widget.offsetHeight > 0) {
  //         widget.click()
  //       }
  //     }
  //     if (instance.upcomingRacesContainerConfig.section < 1) {
  //       document.querySelector('.race-col-race-upcoming-container').scrollIntoView({
  //         behavior: 'smooth'
  //       });
  //       console.log('Max height', instance.upcomingRacesContainerConfig)
  //       upcomingContainerSyles.maxHeight = `${instance.upcomingRacesContainerConfig.height}px`
  //       instance.upcomingRacesContainerConfig.section = Math.floor(instance.upcomingRaceCount / 12)
  //       upcomingShowMore.querySelector('img').style.transform = 'unset';
  //     } else {
  //       const pageHeight = upcomingContainer.scrollHeight / (instance.upcomingRacesContainerConfig.section)
  //       upcomingContainerSyles.maxHeight = `${pageHeight}px`;
  //       console.log('This is stagger!', instance.upcomingRacesContainerConfig.section)
  //       if (instance.upcomingRacesContainerConfig.section === 1) {
  //         upcomingShowMore.querySelector('img').style.transform = 'scaleY(-1)';
  //         Rizk.Translations.get('race_showless', (translation) => {
  //           upcomingShowMore.querySelector('span').innerText = translation
  //         })
  //         return;
  //       }
  //     }
  //     Rizk.Translations.get('race_showmore', (translation) => {
  //       upcomingShowMore.querySelector('span').innerText = translation
  //     })
  //   });
  //   upcomingContainer.parentElement.appendChild(upcomingShowMore)
  // }

  instance.createRaceGameModal = function (raceGame) {
    const raceGames = raceGame.game_whitelist
    console.log('called!')
    const id = 'races'
    Rizk.ModalController.closeModal(id)
    if (Rizk.ModalController.getModal(id) === undefined) {
      const componentFragment = template
      var modal = Rizk.ModalFactory.createFromWebComponent(
        id,
        componentFragment,
        20
      )
      Rizk.ModalController.addModal(modal)
    }
    const raceModal = document.getElementById('race-games-modal')
    const raceGameImageContainer = raceModal.querySelector('.race-col-race-img-container')
    const titleTranslation = raceModal.querySelector('.results-title')
    const optinBtn = raceModal.querySelector('.race-optin')
    Rizk.Translations.get('races_pick_game', (translation) => {
      titleTranslation.innerText = translation
    })
    if (RizkConfig.logged) {
      if (raceGame.current) {
        optinBtn.classList.add('show')
        Rizk.Translations.get('races_join_ongoing', (translation) => {
          optinBtn.innerText = translation
        })
        optinBtn.style.background = ''
        optinBtn.disabled = false
        if (raceGame.optin) {
          optinBtn.classList.remove('show')
          Rizk.Translations.get('races_joined', (translation) => {
            optinBtn.innerText = translation
          })
          optinBtn.disabled = true
        }
      } else {
        Rizk.Translations.get('races_starting_soon', (translation) => {
          optinBtn.innerText = translation
        })
        optinBtn.style.background = 'var(--races-disabled-bg)'
        optinBtn.style.color = 'var(--races-disabled-color)'
        optinBtn.disabled = true
      }
    } else {
      Rizk.Translations.get('races_login_to_join', (translation) => {
        optinBtn.innerText = translation
      })
    }
    const event = new CustomEvent('optinEvent', { detail: raceGame });
    optinBtn.addEventListener('click', function () {
      optinBtn.disabled = true
      if (RizkConfig.logged) {
        if (raceGame.current) {
          console.log('opt in to', raceGame)
          var optinCb = function (response) {
            var json = JSON.parse(response.responseText)
            console.log('opt in cb', json)
            if (!json.success) {
              Rizk.ModalController.closeModal(id)
              console.log('trying to display errormsg')
              if (json.message) {
                console.log('displaying error msg')
                showNotificationModal({
                  id: 'race-restricted',
                  heading: json.message.title,
                  text: json.message.content,
                  classname: 'red',
                })
              } else {
                showGenericErrorModal()
              }
              return
            }
            raceGame.optin = true
            optinBtn.classList.remove('show')
            document.dispatchEvent(event);
          }
          ajax('/race/ongoing/' + raceGame.raceId, optinCb)
        }
      } else {
        Rizk.ModalController.closeModal(id)
        showLoginModal()
      }
    })
    refreshContainer(raceGameImageContainer)
    let index = 0;
    while (raceGames[index]) {
      const raceGameIndividual = raceGameImageContainer.querySelector('.race-game').cloneNode(true)
      raceGameIndividual.classList.add('raceClone')
      const raceGameInfo = raceGames[index];
      const img = raceGameIndividual.querySelector('.race-modal-race-img')
      const src = getGameTileSrc(raceGameInfo)
      img.style.backgroundImage = `url(${src})`
      raceGameIndividual.querySelector('.race-game-name').innerText = raceGameInfo.name
      if(Rizk.LobbyV2.allGames !== null && Rizk.LobbyV2.allGames.length > 0) {
        addExclusiveGameBanner(checkIfGameIsExclusive(raceGameInfo.id), raceGameIndividual)
      } else {
        fetchExclusiveGame(raceGameInfo.id, raceGameIndividual);
      }
      raceGameIndividual.style.display = 'block';
      // eslint-disable-next-line no-loop-func
      raceGameIndividual.addEventListener('click', () => {
        Rizk.GameView.beforeLoadGame(raceGameInfo.url)
      })
      raceGameIndividual.style.cursor = 'pointer'
      raceGameImageContainer.appendChild(raceGameIndividual)
      console.log('race game clone', raceGameImageContainer, raceGameInfo)
      index++
    }
    raceModal.appendChild(raceGameImageContainer)
    Rizk.ModalController.showModal(id, 20)
  }

  instance.updateUserStats = function (race, lb = {
    maxRounds: race.maxRounds,
    winsInRow: 3,
    lossesInRow: 3,
  }) {
    console.log('race stats', race.raceId)
    const raceIndividual = document.getElementById(`race-${race.raceId}`)
    if (!raceIndividual) {
      return
    }
    const raceColumn = raceIndividual.querySelector('.race-col')
    if (!raceColumn) {
      return
    }
    const spins = raceColumn.querySelector('.spins')
    Rizk.Translations.get('race-spins-left', (translation) => {
      spins.innerText = translation
    });
    var statsElem = document.createElement('div');
    statsElem.className = 'race-user-entry-stats';

    var spinsElem = document.createElement('p');
    spinsElem.textContent = Rizk.Translations.get('race_mypoints');

    var spinsSpan = raceColumn.querySelector('#race-spins')
    var mElemLabel = document.createElement('span');
    var mElem = raceColumn.querySelector('#race-duration-streak');
    mElem.classList.add('lb-meters');
    var winMeter = NewItemList(lb.winsInRow, 'winmeter');
    var lossMeter = NewItemList(lb.lossesInRow, 'lossmeter');
    mElem.querySelectorAll('span').forEach(element => {
      element.style.display = 'none';
    })

    mElem.appendChild(mElemLabel);

    if(document.querySelector('#game-aside') === null) {
      mElemLabel.id = 'testingposition';
      Rizk.Translations.get('race.myposition', (translation) => {
        mElemLabel.innerText = translation
      });
      let userRacePosition = race.leaderboard || [];
      var userPos = null;
      var userPosition = document.createElement('span');
      userPosition.id = 'testinguserposition';
      userRacePosition = sortPointsArray(userRacePosition)
      if (userRacePosition !== null && userRacePosition.length) {
        userRacePosition.forEach((user, pos) => {
          if (parseInt(user.uid) === parseInt(RizkConfig.userId)) {
            userPos = pos;
          }
        });
        if(userPos !== null) {
          userPosition.textContent = String((parseInt(userPos) + 1));
          userPosition.classList.add('position-assigned');
        } else {
          Rizk.Translations.get('race.position-not-found', (translation) => {
            userPosition.innerText = translation
          });
        }
      } else {
        Rizk.Translations.get('race.position-not-found', (translation) => {
          userPosition.innerText = translation
        });
      }
      mElem.appendChild(userPosition);
    } else {
      mElemLabel.textContent = Rizk.Translations.get('race_streak');
      mElem.appendChild(winMeter.elem);
      mElem.appendChild(lossMeter.elem);
    }

    var entry = {
      elem: statsElem,
      updatePoints: function (points) {
        if (!instance.activeRacePoints[`${race.raceId}`]) {
          return
        }
        instance.maxPoints = points
        console.log('active race points', instance.activeRacePoints[`${race.raceId}`], points)
        instance.updateRacePoints(race.raceId, null, null, true, race)
      },
      updateCurrentPoints: function (points) {
        if (!instance.activeRacePoints[`${race.raceId}`]) {
          return
        }
        instance.currentPoints = points
        instance.updateRacePoints(race.raceId, null, true,  race)
      },
      update: function (spins, wins, losses) {
        spinsSpan.textContent = race.maxRounds - spins;
        winMeter.update(wins);
        lossMeter.update(losses);
        // show win meter if both are 0
        if (wins === 0 && losses === 0) {
          winMeter.show();
        }
      },
      updateUserPosition: function () {
        console.log('testing here')
      }
    };
    entry.update(0, 0, 0);
    entry.updatePoints(0);
    return entry;
  };

  instance.updateRaceStats = function (data) {
    if (stats) {
      stats.update(data.spins, data.wins, data.losses)
      stats.updatePoints(data.points)
      stats.updateCurrentPoints(data.currentPoints)
      stats.updateUserPosition()
    }
  }

  const NewItemList = function (nItems, className) {
    var n = nItems;
    var i, e2, e = document.createElement('ul');
    e.className = 'item-list';
    var entries = [];
    for (i = 0; i < n; i++) {
      e2 = document.createElement('li');
      e2.className = className + `-${RizkConfig.brand}`;
      e.appendChild(e2);
      entries.push(e2);
    }
    var hide = function () {
      e.style.visibility = 'hidden';
      e.style.display = 'none';
    };
    var show = function () {
      e.style.display = 'inline-block';
      e.style.visibility = 'visible';
    };

    var update = function (nn) {
      for (i = 0; i < nn; i++) {
        entries[i].classList.add('active');
      }
      for (i = nn; i < n; i++) {
        entries[i].classList.remove('active');
      }
      if (nn === 0) {
        hide();
      }
      else {
        show();
      }
    };
    return {
      elem: e,
      update: update,
      hide: hide,
      show: show
    };
  };

  const fetchExclusiveGame = function(gameId, raceGameElement) {
    ajax(
      '/all/exclusive/games/' + gameId,
        function (x) {
            if (x.status === 200) {
                if (x.response !== null) {
                  var response = JSON.parse(x.response);
                  addExclusiveGameBanner(response.isExclusive, raceGameElement)
                }
            }
        },
        null,
        false,
        true,
        'application/json'
    )
  }

  const addExclusiveGameBanner = function(isExclusive, raceGameEl) {
    if(isExclusive === true) {
      raceGameEl.querySelector('.exclusive-tag').classList.add('exclusive')
      raceGameEl.style.marginTop = '-18px';
      Rizk.Translations.get('casino.game.exclusive', (translation) => {
        if(raceGameEl.querySelector('.exclusive-tag-container'))
        {raceGameEl.querySelector('.exclusive-tag-container').querySelector('p').innerText = translation;}
      })
    } else {
      raceGameEl.querySelector('.exclusive-tag').classList.remove('exclusive')
    }
  }

  const checkIfGameIsExclusive = function(gameId) {
    var data = Rizk.LobbyV2.allGames;
    var isExclusive = false;

    for (let [key, category] of Object.entries(data)) {
        if(category && category.categoryGamesList.games) {
          for (let [key, value] of Object.entries(category.categoryGamesList.games)) {
            if(value.id === gameId && parseInt(value.isExclusive) === 1) {
                  isExclusive = true;
                }
            };
        }
    }

    return isExclusive;
  }

  const countDownPush = (intervalID) => {
    instance.countdownIntervals.push(intervalID)
    console.log('pushed id', intervalID, instance.countdownIntervals)
  }

  const clearCountDown = () => {
    while (instance.countdownIntervals.length) {
      clearInterval(instance.countdownIntervals[0])
      instance.countdownIntervals.shift()
      console.log('removed id', instance.countdownIntervals[0], instance.countdownIntervals)
    }
  }
  return instance
}


// Listen for the event.
document.addEventListener('optinEvent', function (e) {
  const race = e.detail
  const raceElement = document.getElementById(`race-${race.raceId}`)
  const optinBtn = raceElement.querySelector('.race-optin')
  Rizk.Translations.get('races_pick_game', (translation) => {
    optinBtn.innerText = translation
  })
  optinBtn.disabled = false
  raceElement.querySelector('.count').innerHTML = race.optincount + 1
  if(RizkConfig.inGame){
    Rizk.GameView.handleRace(race)
  }else{
    Rizk.Race.startRace(race)
  }
}, false);
