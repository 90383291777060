export default class MenuItemLinks {
    // check if a menu link is active
    linksActive() {

      const linkElements = Array.from(document.querySelectorAll('a[data-parent]'));
      const parentContainer = document.querySelector('[data-parent]:not(a)');
      if (RizkConfig.locale === 'pe') {
        const sportsBookIframe = document.querySelector('.sportsBookIframe-wrapper');
        if ((sportsBookIframe) && (window.location.pathname.includes('en-vivo'))) {
          sportsBookIframe.setAttribute('data-parent', 'live-sports');
        }
      }
      const highlightLinks = (parentElement) => {
        if (parentElement) {
          const parentData = parentElement.getAttribute('data-parent');
          const currentLink = linkElements.find(linkElem => linkElem.classList.contains('menu-item-active'));
          if (currentLink) {
            currentLink.classList.remove('menu-item-active');
          }

          const newLinks = linkElements.filter(linkElem => linkElem.getAttribute('data-parent') === parentData);
          newLinks.forEach(link => {
            if (link) {
              if ((RizkConfig.locale === 'hr') && (RizkConfig.logged === true)) {
                document.querySelector('#menu-item-wheelofrizk').classList.remove('menu-item-active');
              }
              link.classList.add('menu-item-active');
            }
          })
        }
      };
      if (parentContainer) {
        highlightLinks(parentContainer);
     }     
      if (RizkConfig.logged) {

        if (document.querySelector('.rewards-menu-item') !== null) {
          document.querySelector('.rewards-menu-item').addEventListener('click', () => {
            const contentParentMudalRewards = document.querySelector('#mudal-rewards');
            if (contentParentMudalRewards) {
              highlightLinks(contentParentMudalRewards);
            }
          });
        }
        if (document.querySelector('#side-menu-account') !== null){
          document.querySelector('#side-menu-account').addEventListener('click', () => {
            const contentParentMudalAccount = document.querySelector('#mudal-account-settings');
            if (contentParentMudalAccount) {
              const parentOfContentParentMudalAccount = contentParentMudalAccount.closest('*[data-parent]');
              highlightLinks(parentOfContentParentMudalAccount);
            }
          });
        }
        if (document.querySelector('#side-menu-inbox') !== null){
          document.querySelector('#side-menu-inbox').addEventListener('click', () => {
            const contentParentMudalAccount = document.querySelector('#mudal-account-settings');
            if (contentParentMudalAccount) {
              const parentOfContentParentMudalAccount = contentParentMudalAccount.closest('*[data-parent]');
              highlightLinks(parentOfContentParentMudalAccount);
            }
          });
        }
      }

      const footerLinks = document.querySelectorAll('.footer-links a');
      footerLinks.forEach(a => {
        const linkPath = a.href;
          if (linkPath === window.location.href) {
            const currentLink = linkElements.find(linkElem => linkElem.classList.contains('menu-item-active'));
            if (currentLink) {
              currentLink.classList.remove('menu-item-active');
            }
            a.classList.add('menu-item-active');
            return;
          }
      });
      
    }
}





